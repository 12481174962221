import React from "react";
import '../../styles/semantic.min.css';
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import Supporter from "../../components/supporters/supporter";
import PageHeader from "../../components/utils/PageHeader";
import {Container, Header, Segment} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import styles from "../../components/supporters/supporters.module.css";
import layout from "../../templates/layout.module.css";
import PrimaryButton from "../../components/utils/buttons/PrimaryButton";
import SupporterMobile from "../../components/supporters/SupporterMobile";

export default () => <LayoutContainer>
    <SEO title={'Freunde'}/>
    <MobileTabletContainer className={styles.mobile}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Unsere Freunde'} subHeader={'Du bist die Stiftung'}/>
            <Supporter/>
        </Container>
        <SupporterMobile/>
        <Footer/>
    </MobileTabletContainer>
    <DesktopContainer className={styles.desktop}>
        <div className={layout.pageWrapper}>
        <MainNavigation/>
        <Container fluid>
            <PageHeader title={'Unsere Freunde'} subHeader={'Du bist die Stiftung'}/>
            <Container>
                <Segment>
                    <div style={{ textAlign: 'center', padding: '1em 2em'}}>
                    <Header as={'h2'} textAlign={'center'} style={{marginBottom: '2rem'}}>
                        Mit dem Engagement jedes Einzelnen gelingt der Wandel!
                    </Header>
                    <p>Du bist die Stiftung - Die DSK ruft zum gemeinsamen sozialen Engagement auf. Zeigen Sie Ihre
                        Unterstützung indem Sie eine Botschaft mit Bild hochladen.</p>
                        <div style={{display: "table", margin: "0 auto"}}>
                        <PrimaryButton link={'/freunde/freund-werden/'}>
                            Jetzt Freund werden
                        </PrimaryButton>
                        </div>
                    </div>
                </Segment>
            </Container>
            <Supporter/>
        </Container>
        <Footer/>
        </div>
    </DesktopContainer>



</LayoutContainer>