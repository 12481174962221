import React from 'react';
import {Card, Segment,} from 'semantic-ui-react';
import styles from "./supporters.module.css";
import {graphql, StaticQuery} from "gatsby";
import Img from "../utils/Img";

const SupporterMobile = () => (
    <StaticQuery
        query={friendMobileQuery}
        render={data => {
            const values = data.allMarkdownRemark.edges;
            const Friends = values.map(i =>
                    <Card className={styles.cardPreview}>
                        {
                            !!i.node.frontmatter.image &&
                            <Img className={styles.testimonialPicPreview}
                                 fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                        }
                        <Card.Content>
                            <Card.Header className={styles.cardHeader}>{i.node.frontmatter.title}</Card.Header>
                            <Card.Description className={styles.quotePreview}
                                              dangerouslySetInnerHTML={{__html: i.node.html}}>
                            </Card.Description>
                        </Card.Content>
                    </Card>
            );
            return (<>
                <Segment className={styles.main}>
                            {Friends}
                </Segment>
            </>)
        }
        }
    />
);

export default SupporterMobile;

const friendMobileQuery = graphql`
  query friendMobileQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "friends" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] },
      skip: 5
      ){
      totalCount
    edges {
      node {
        html
        frontmatter{
          title
          firstname
          lastname
          image{
            childImageSharp {
              fluid(maxHeight: 500 ) {
                  ...GatsbyImageSharpFluid_noBase64
                }
            }
          }
        }
      }
    }
  }
  }
`;